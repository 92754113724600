<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <DocumentsList />
      </div>
    </div>
  </div>
</template>
<script>
import DocumentsList from './components/DocumentsList.vue';

export default {
  name: 'Document',
  components: { DocumentsList },
};
</script>
